import React from 'react'

const Footer = class extends React.Component {
  render() {
    return (
      <div className="footer-wrap">
        <footer className="has-background-grey-darker has-text-grey-lighter has-text-centered" style={{ padding: '0.75rem' }}>
          <div className="container">
            <p className="is-size-7">Copyright &copy; {new Date().getFullYear()} Mast Cell Hope, Inc. | All rights reserved</p>
          </div>
        </footer>
      </div>
    )
  }
}

export default Footer
