import React from 'react'

const Logo = class extends React.Component {
    render() {
        if(this.props.format === "mono") {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169 50" style={{ fill: this.props.color }} className="mch-logo">
                <g>
                    <path d="M69.228,22.381h-6.66v-1.49c0-0.467,0.234-0.759,0.696-0.869l0.021-0.005h0.021c0.044-0.003,0.405-0.051,1.255-0.173
                        v-7.992c0-0.103,0.006-0.205,0.012-0.305c0.004-0.066,0.008-0.131,0.01-0.199l-4.436,8.342c-0.133,0.238-0.313,0.436-0.508,0.557
                        c-0.221,0.139-0.457,0.207-0.719,0.207h-0.398c-0.263,0-0.498-0.068-0.718-0.207c-0.197-0.121-0.376-0.318-0.508-0.553
                        l-4.494-8.357c0.012,0.18,0.012,0.355,0.012,0.516v7.991c0.729,0.104,1.202,0.17,1.261,0.174h0.021l0.015,0.005
                        c0.463,0.11,0.697,0.402,0.697,0.869v1.49h-6.661v-1.49c0-0.467,0.234-0.759,0.695-0.869l0.021-0.005h0.021
                        c0.017,0,0.037,0,0.075-0.01c0.078-0.028,0.197-0.051,0.365-0.077c0.097-0.015,0.233-0.035,0.386-0.062
                        c0.039-0.006,0.079-0.013,0.12-0.02V8.526c-0.04-0.004-0.081-0.011-0.119-0.019c-0.152-0.028-0.289-0.049-0.387-0.064
                        c-0.088-0.014-0.161-0.029-0.219-0.045c-0.034-0.01-0.075-0.018-0.089-0.018L48.99,8.379l-0.032-0.008
                        c-0.038-0.014-0.059-0.014-0.075-0.014h-0.021l-0.021-0.006c-0.461-0.109-0.695-0.398-0.695-0.865V5.992h4.106
                        c0.08,0,0.159,0.008,0.229,0.015c0.048,0.005,0.094,0.009,0.126,0.009c0.108,0,0.235,0.024,0.316,0.053
                        c0.119,0.031,0.188,0.107,0.245,0.164c0.01,0.013,0.021,0.025,0.033,0.039c0.077,0.075,0.154,0.178,0.227,0.299l4.79,8.891
                        c0.092,0.172,0.164,0.34,0.233,0.506c0.035,0.078,0.067,0.156,0.105,0.239c0.052,0.116,0.106,0.239,0.159,0.364
                        c0.046-0.121,0.093-0.236,0.145-0.359c0.128-0.311,0.239-0.549,0.361-0.771l4.741-8.86c0.018-0.029,0.031-0.053,0.042-0.071
                        c0.051-0.09,0.098-0.17,0.167-0.236c0.076-0.078,0.17-0.172,0.308-0.205c0.094-0.025,0.198-0.051,0.312-0.051
                        c0.029,0,0.069-0.004,0.114-0.009C64.968,6,65.04,5.992,65.123,5.992h4.105v1.494c0,0.467-0.233,0.756-0.695,0.865l-0.028,0.006
                        c-0.03,0.009-0.08,0.023-0.147,0.023c-0.013,0-0.054,0.008-0.088,0.018c-0.058,0.016-0.131,0.031-0.217,0.045
                        c-0.087,0.013-0.191,0.033-0.314,0.054c-0.055,0.011-0.11,0.021-0.171,0.028v11.322c0.061,0.012,0.117,0.02,0.171,0.029
                        c0.123,0.023,0.229,0.04,0.314,0.053c0.086,0.014,0.159,0.031,0.217,0.046c0.034,0.009,0.075,0.021,0.089,0.021l0,0
                        c0.067,0,0.117,0.012,0.146,0.02l0.028,0.006c0.462,0.11,0.695,0.402,0.695,0.869V22.381z"/>
                    <path d="M87.018,22.381h-5.774v-1.314c0-0.33,0.18-0.573,0.55-0.743c0.02-0.011,0.049-0.022,0.099-0.037
                        c0.048-0.019,0.11-0.044,0.189-0.074l-0.03-0.079l-0.753-2.049h-6.121l-0.768,2.135c0.071,0.027,0.13,0.05,0.175,0.067
                        c0.047,0.015,0.078,0.026,0.102,0.04c0.366,0.167,0.546,0.41,0.546,0.74v1.314h-5.773v-1.314c0-0.33,0.179-0.573,0.549-0.743
                        l0.01-0.004c0.086-0.034,0.379-0.159,0.821-0.345l0.321-0.135l5.424-13.848h3.307l5.446,13.848l0.332,0.139
                        c0.437,0.184,0.727,0.308,0.812,0.341c0.351,0.134,0.537,0.392,0.537,0.747V22.381z M76.087,15.607h4.322l-1.746-4.736
                        c-0.048-0.13-0.096-0.266-0.146-0.412c-0.044-0.127-0.091-0.262-0.144-0.407c-0.051-0.144-0.097-0.299-0.142-0.458
                        c-0.045,0.149-0.088,0.298-0.125,0.441c-0.071,0.27-0.157,0.494-0.232,0.691c-0.021,0.057-0.042,0.112-0.062,0.168L76.087,15.607z"
                        />
                    <path d="M92.35,22.558h-0.333c-1.41,0-2.972-0.287-4.646-0.853l-0.124-0.042v-4.437h1.447c0.367,0,0.599,0.181,0.688,0.537
                        c0.045,0.205,0.107,0.757,0.193,1.742c0.612,0.167,1.551,0.246,2.862,0.246c0.801,0,1.423-0.187,1.852-0.553
                        c0.419-0.361,0.623-0.879,0.623-1.588c0-0.369-0.094-0.695-0.274-0.932c-0.176-0.234-0.438-0.44-0.776-0.609
                        c-0.376-0.188-0.739-0.332-1.145-0.455c-0.157-0.047-0.317-0.092-0.479-0.137c-0.29-0.079-0.59-0.161-0.88-0.264
                        c-0.444-0.148-0.892-0.323-1.366-0.539c-0.429-0.193-0.848-0.48-1.246-0.854c-0.375-0.351-0.658-0.779-0.865-1.309
                        c-0.227-0.549-0.346-1.209-0.346-1.904c0-0.624,0.125-1.223,0.37-1.78c0.226-0.563,0.593-1.101,1.061-1.544
                        c0.444-0.422,1-0.769,1.696-1.058c0.663-0.272,1.438-0.411,2.308-0.411c1.571,0,3.138,0.312,4.656,0.924l0.115,0.047v4.407h-1.446
                        c-0.252,0-0.574-0.097-0.689-0.562c-0.046-0.207-0.108-0.765-0.195-1.712c-0.519-0.198-1.33-0.3-2.418-0.3
                        c-0.388,0-0.707,0.043-1.008,0.127c-0.312,0.11-0.544,0.238-0.707,0.384c-0.196,0.173-0.349,0.374-0.443,0.579
                        c-0.091,0.199-0.139,0.455-0.139,0.742c0,0.307,0.098,0.579,0.291,0.814c0.198,0.242,0.453,0.443,0.761,0.594
                        c0.427,0.217,0.798,0.365,1.166,0.477c0.334,0.1,0.662,0.212,1.008,0.327c0.115,0.038,0.231,0.077,0.349,0.119
                        c0.475,0.155,0.928,0.361,1.368,0.56c0.46,0.205,0.88,0.484,1.247,0.831c0.399,0.375,0.689,0.778,0.887,1.237
                        c0.216,0.456,0.324,1.038,0.324,1.778c0,0.719-0.124,1.381-0.39,2.079c-0.26,0.689-0.63,1.254-1.129,1.729
                        c-0.482,0.482-1.092,0.87-1.811,1.148C94.049,22.424,93.26,22.558,92.35,22.558z"/>
                    <path d="M108.373,22.381h-6.705v-1.49c0-0.467,0.234-0.759,0.697-0.869l0.027-0.006c0.031-0.008,0.082-0.02,0.148-0.02
                        c0.012,0,0.055-0.012,0.087-0.021c0.06-0.015,0.133-0.032,0.219-0.046c0.085-0.013,0.191-0.029,0.314-0.053
                        c0.053-0.01,0.11-0.018,0.169-0.029V8.802h-2.904c-0.043,0.442-0.083,0.702-0.097,0.774l-0.002,0.016
                        c-0.117,0.463-0.438,0.561-0.688,0.561h-1.469v-4.16h13.684v4.16h-1.449c-0.362,0-0.621-0.205-0.709-0.561l-0.004-0.016
                        c-0.036-0.211-0.067-0.466-0.097-0.754h-2.903v11.025c0.059,0.012,0.116,0.02,0.17,0.029c0.122,0.023,0.229,0.04,0.312,0.053
                        c0.089,0.014,0.161,0.031,0.219,0.046c0.034,0.009,0.076,0.021,0.089,0.021l0,0c0.065,0,0.116,0.012,0.147,0.02l0.027,0.006
                        c0.471,0.112,0.718,0.41,0.718,0.869V22.381z"/>
                    <path d="M124.716,22.558c-1.175,0-2.242-0.206-3.262-0.633c-0.996-0.417-1.839-1-2.502-1.729c-0.702-0.774-1.236-1.668-1.588-2.656
                        c-0.366-1.027-0.543-2.121-0.543-3.342c0-1.234,0.206-2.377,0.611-3.39c0.406-1.019,0.971-1.879,1.725-2.633
                        c0.783-0.781,1.652-1.354,2.655-1.748c1.013-0.403,2.162-0.61,3.412-0.61c1.373,0,3.086,0.393,5.097,1.163l0.118,0.046v4.39h-1.472
                        c-0.366,0-0.598-0.181-0.687-0.54c-0.045-0.2-0.107-0.734-0.193-1.674c-1.11-0.313-2.054-0.468-2.886-0.468
                        c-0.763,0-1.421,0.118-2.011,0.362c-0.611,0.254-1.118,0.606-1.552,1.084c-0.44,0.485-0.777,1.045-1.027,1.715
                        c-0.257,0.683-0.388,1.457-0.388,2.303c0,0.874,0.127,1.637,0.388,2.33c0.279,0.743,0.582,1.272,0.983,1.711
                        c0.411,0.455,0.899,0.801,1.489,1.066c0.554,0.242,1.135,0.359,1.83,0.359c0.396,0,0.762-0.023,1.084-0.066
                        c0.331-0.045,0.625-0.106,0.875-0.192c0.238-0.078,0.492-0.2,0.734-0.355c0.21-0.133,0.441-0.314,0.706-0.557
                        c0.082-0.081,0.189-0.136,0.285-0.183c0.129-0.065,0.251-0.065,0.349-0.065c0.235,0,0.431,0.083,0.562,0.244l1.276,1.395
                        l-0.1,0.124c-0.666,0.829-1.495,1.464-2.462,1.892C127.234,22.338,126.056,22.558,124.716,22.558z"/>
                    <path d="M143,22.381h-12.156v-1.49c0-0.467,0.234-0.759,0.697-0.869l0.028-0.006c0.03-0.008,0.081-0.02,0.147-0.02
                        c0.014,0,0.055-0.012,0.087-0.021c0.059-0.015,0.132-0.032,0.218-0.046c0.1-0.015,0.233-0.036,0.389-0.062
                        c0.038-0.006,0.077-0.013,0.119-0.02V8.526c-0.042-0.004-0.081-0.011-0.118-0.019c-0.155-0.028-0.29-0.049-0.39-0.064
                        c-0.086-0.014-0.159-0.029-0.218-0.045c-0.033-0.01-0.074-0.018-0.089-0.018l0,0c-0.064,0-0.115-0.015-0.145-0.023l-0.028-0.006
                        c-0.463-0.109-0.697-0.398-0.697-0.865V5.992H143v4.581h-1.47c-0.25,0-0.572-0.098-0.688-0.561l-0.005-0.024l-0.011-0.092
                        c-0.025-0.197-0.077-0.586-0.129-1.163h-4.809v4.061h5.429v2.673h-5.429v4.152h4.809c0.053-0.577,0.104-0.969,0.129-1.163
                        l0.016-0.115c0.116-0.464,0.438-0.562,0.688-0.562H143V22.381z"/>
                    <path d="M155.316,22.381h-11.979v-1.49c0-0.467,0.234-0.759,0.698-0.869l0.021-0.005h0.021c0.015,0,0.035,0,0.074-0.01
                        c0.076-0.028,0.196-0.051,0.364-0.077c0.099-0.015,0.233-0.036,0.387-0.062c0.039-0.006,0.078-0.013,0.118-0.02V8.526
                        c-0.04-0.004-0.079-0.011-0.117-0.019c-0.154-0.028-0.289-0.049-0.388-0.064c-0.087-0.014-0.159-0.029-0.219-0.045
                        c-0.032-0.01-0.074-0.018-0.089-0.018l-0.025-0.002l-0.031-0.008c-0.039-0.014-0.06-0.014-0.074-0.014h-0.021l-0.021-0.006
                        c-0.464-0.109-0.698-0.398-0.698-0.865V5.992h6.706v1.494c0,0.467-0.234,0.756-0.695,0.865l-0.021,0.006h-0.021
                        c-0.017,0-0.038,0-0.075,0.014c-0.078,0.023-0.197,0.05-0.365,0.072c-0.098,0.016-0.235,0.036-0.389,0.064
                        c-0.037,0.008-0.078,0.016-0.117,0.019v11.025h4.807l0.005-0.047c0.035-0.463,0.053-0.673,0.076-0.764
                        c0.117-0.467,0.439-0.562,0.688-0.562h1.381V22.381z"/>
                    <path d="M167.612,22.381h-11.978v-1.49c0-0.467,0.233-0.759,0.695-0.869l0.021-0.005h0.021c0.016,0,0.037,0,0.075-0.01
                        c0.078-0.028,0.197-0.051,0.364-0.077c0.098-0.015,0.233-0.035,0.387-0.062c0.038-0.006,0.079-0.013,0.12-0.02V8.526
                        c-0.04-0.004-0.081-0.011-0.118-0.019c-0.155-0.028-0.29-0.049-0.389-0.064c-0.086-0.014-0.16-0.029-0.217-0.045
                        c-0.034-0.01-0.075-0.018-0.089-0.018l-0.025-0.002l-0.032-0.008c-0.038-0.014-0.059-0.014-0.075-0.014h-0.021l-0.021-0.006
                        c-0.462-0.109-0.695-0.398-0.695-0.865V5.992h6.705v1.494c0,0.467-0.234,0.756-0.697,0.865l-0.021,0.006h-0.021
                        c-0.015,0-0.035,0-0.075,0.014c-0.076,0.023-0.195,0.05-0.363,0.072c-0.1,0.016-0.234,0.036-0.39,0.064
                        c-0.038,0.008-0.076,0.016-0.117,0.019v11.025h4.807l0.004-0.047c0.038-0.463,0.054-0.673,0.077-0.764
                        c0.115-0.467,0.438-0.562,0.688-0.562h1.381V22.381z"/>
                    <path d="M91.329,44.119h-7.325v-1.648c0-0.514,0.269-0.849,0.778-0.967l0.026-0.006h0.025c0.017,0,0.035,0,0.071-0.014
                        c0.087-0.027,0.217-0.055,0.4-0.08c0.091-0.015,0.206-0.033,0.338-0.059c0.052-0.01,0.104-0.017,0.16-0.027v-4.783H78.13v4.783
                        c0.035,0.006,0.07,0.013,0.104,0.018c0.165,0.028,0.312,0.051,0.417,0.068c0.096,0.014,0.176,0.031,0.239,0.049
                        c0.033,0.01,0.077,0.021,0.091,0.022h0.038l0.032,0.009c0.037,0.014,0.056,0.014,0.07,0.014h0.027l0.026,0.006
                        c0.509,0.118,0.778,0.453,0.778,0.967v1.648h-7.351v-1.648c0-0.514,0.27-0.849,0.778-0.967l0.032-0.006
                        c0.031-0.01,0.091-0.022,0.165-0.022c0.011-0.001,0.055-0.013,0.088-0.022c0.063-0.017,0.144-0.035,0.24-0.049
                        c0.105-0.018,0.251-0.04,0.417-0.068c0.033-0.005,0.068-0.012,0.103-0.018V29.106c-0.033-0.005-0.066-0.009-0.096-0.015
                        c-0.171-0.031-0.319-0.055-0.424-0.071c-0.097-0.013-0.177-0.032-0.24-0.05c-0.033-0.009-0.077-0.018-0.091-0.018h-0.038
                        l-0.032-0.014c-0.037-0.013-0.056-0.013-0.07-0.013h-0.027l-0.026-0.007c-0.509-0.117-0.778-0.453-0.778-0.967v-1.647h7.351v1.647
                        c0,0.514-0.27,0.85-0.778,0.967l-0.026,0.007h-0.027c-0.015,0-0.033,0-0.07,0.013l-0.035,0.014h-0.038
                        c-0.011,0-0.055,0.009-0.088,0.018c-0.063,0.018-0.144,0.037-0.239,0.05c-0.107,0.017-0.256,0.04-0.424,0.071
                        c-0.031,0.006-0.064,0.01-0.097,0.015v4.663h7.673v-4.664c-0.056-0.006-0.106-0.017-0.157-0.026
                        c-0.134-0.024-0.25-0.045-0.341-0.059c-0.161-0.021-0.281-0.046-0.366-0.069c-0.057-0.004-0.101-0.018-0.127-0.024h-0.004
                        l-0.026-0.007c-0.51-0.117-0.778-0.453-0.778-0.967v-1.647h7.325v1.647c0,0.514-0.269,0.85-0.777,0.967l-0.031,0.007
                        c-0.033,0.009-0.091,0.026-0.166,0.026c-0.013,0-0.057,0.009-0.087,0.018c-0.065,0.018-0.145,0.037-0.24,0.05
                        c-0.091,0.014-0.206,0.034-0.341,0.059c-0.049,0.01-0.102,0.021-0.157,0.026v12.213c0.057,0.011,0.109,0.018,0.159,0.027
                        c0.135,0.025,0.249,0.044,0.339,0.059c0.096,0.014,0.175,0.031,0.24,0.049c0.03,0.01,0.074,0.021,0.09,0.022
                        c0.001,0,0.001,0,0.001,0c0.071,0,0.129,0.013,0.162,0.022h0.006l0.025,0.006c0.509,0.118,0.777,0.453,0.777,0.967V44.119z"/>
                    <path d="M101.922,44.311c-1.303,0-2.543-0.23-3.685-0.688c-1.105-0.443-2.069-1.082-2.864-1.903
                        c-0.786-0.807-1.407-1.779-1.848-2.881c-0.426-1.065-0.642-2.286-0.642-3.64s0.21-2.535,0.642-3.613
                        c0.44-1.102,1.062-2.07,1.848-2.882c0.795-0.821,1.759-1.46,2.864-1.899c1.143-0.461,2.382-0.691,3.685-0.691
                        c1.367,0,2.566,0.225,3.667,0.691c1.159,0.488,2.095,1.111,2.859,1.899c0.769,0.793,1.366,1.736,1.824,2.882
                        c0.44,1.104,0.665,2.318,0.665,3.613c0,1.311-0.225,2.535-0.665,3.64c-0.458,1.146-1.056,2.089-1.824,2.881
                        c-0.795,0.821-1.758,1.46-2.864,1.903C104.427,44.087,103.229,44.311,101.922,44.311z M101.922,29.336
                        c-0.865,0-1.609,0.131-2.212,0.389c-0.639,0.272-1.192,0.666-1.648,1.166c-0.458,0.508-0.806,1.109-1.057,1.841
                        c-0.254,0.736-0.371,1.524-0.371,2.468c0,0.951,0.121,1.768,0.371,2.494c0.251,0.735,0.599,1.336,1.057,1.84
                        c0.456,0.5,1.01,0.895,1.648,1.167c0.603,0.261,1.347,0.391,2.212,0.391c0.863,0,1.578-0.13,2.19-0.391
                        c0.639-0.272,1.192-0.667,1.648-1.167c0.469-0.516,0.805-1.115,1.03-1.834c0.251-0.805,0.373-1.62,0.373-2.5
                        c0-0.871-0.118-1.657-0.373-2.475c-0.226-0.717-0.562-1.318-1.03-1.834c-0.456-0.5-1.01-0.894-1.649-1.166
                        C103.5,29.463,102.785,29.336,101.922,29.336z"/>
                    <path d="M119.842,44.119h-7.327v-1.648c0-0.514,0.271-0.849,0.779-0.967l0.031-0.006c0.027-0.008,0.07-0.021,0.125-0.021
                        c0.085-0.026,0.207-0.048,0.368-0.072c0.105-0.018,0.252-0.04,0.418-0.068c0.034-0.006,0.068-0.012,0.104-0.018V29.106
                        c-0.034-0.005-0.067-0.009-0.099-0.015c-0.171-0.031-0.318-0.055-0.424-0.071c-0.096-0.013-0.177-0.032-0.24-0.05
                        c-0.032-0.009-0.076-0.018-0.09-0.018h-0.038l-0.033-0.014c-0.038-0.013-0.057-0.013-0.071-0.013h-0.027l-0.025-0.007
                        c-0.509-0.117-0.779-0.453-0.779-0.967v-1.647h7.407c1.152,0,2.224,0.146,3.093,0.418c0.824,0.261,1.555,0.663,2.172,1.2
                        c0.577,0.502,1.011,1.113,1.287,1.816c0.281,0.718,0.424,1.496,0.424,2.314c0,0.886-0.152,1.696-0.45,2.418
                        c-0.309,0.746-0.736,1.363-1.305,1.883c-0.55,0.501-1.287,0.912-2.193,1.225c-0.826,0.283-1.814,0.42-3.027,0.42h-1.904v3.318
                        c0.035,0.006,0.071,0.013,0.104,0.018c0.166,0.028,0.312,0.051,0.417,0.068c0.097,0.014,0.177,0.032,0.24,0.049
                        c0.033,0.01,0.077,0.021,0.091,0.022l0,0c0.071,0,0.131,0.013,0.162,0.022h0.006l0.026,0.006c0.508,0.118,0.778,0.453,0.778,0.967
                        V44.119z M118.018,35.017h1.904c0.582,0,1.081-0.066,1.487-0.202c0.432-0.144,0.746-0.323,0.99-0.568l0.006-0.008
                        c0.259-0.236,0.457-0.543,0.587-0.916c0.133-0.349,0.204-0.799,0.204-1.268c0-0.422-0.066-0.803-0.202-1.166
                        c-0.127-0.338-0.319-0.623-0.589-0.869c-0.252-0.231-0.577-0.411-0.996-0.551c-0.406-0.135-0.905-0.205-1.487-0.205h-1.904V35.017z
                        "/>
                    <path d="M141.705,44.119h-13.23v-1.648c0-0.514,0.27-0.849,0.778-0.967l0.032-0.006c0.033-0.01,0.091-0.022,0.165-0.022
                        c0.011-0.001,0.056-0.013,0.088-0.022c0.063-0.018,0.144-0.035,0.24-0.049c0.105-0.018,0.251-0.04,0.417-0.068
                        c0.035-0.005,0.068-0.012,0.105-0.018V29.106c-0.034-0.005-0.068-0.009-0.1-0.015c-0.171-0.031-0.318-0.055-0.423-0.071
                        c-0.097-0.013-0.177-0.032-0.24-0.05c-0.032-0.009-0.077-0.018-0.091-0.018l0,0c-0.071,0-0.129-0.018-0.162-0.026h-0.006
                        l-0.026-0.007c-0.508-0.117-0.778-0.453-0.778-0.967v-1.647h13.23v5.021h-1.623c-0.399,0-0.674-0.224-0.776-0.632l-0.004-0.026
                        l-0.014-0.101c-0.03-0.227-0.081-0.626-0.137-1.232h-5.149v4.338h5.88v2.958h-5.88v4.433h5.149c0.056-0.605,0.106-1.004,0.137-1.23
                        l0.018-0.131c0.103-0.405,0.377-0.629,0.776-0.629h1.623V44.119z"/>
                </g>
                <g>
                    <g>
                        <path d="M28.116,30.723c0,0,5.928-0.584,6.693-0.691c0.646-0.092,0.83-0.368,0.783-1.039c-0.037-0.552-0.645-11.84-0.668-12.393
                            c-0.023-0.554-0.23-0.738-0.763-0.738c-0.558,0-1.569,0.092-2.007,0.302c0.046,0.415,0.046,0.737,0.092,0.968
                            c0.508-0.209,1.685-0.256,1.685-0.256l0.602,12.223h-7.108L28.116,30.723z"/>
                        <path d="M31.751,14.244c0.057,1.216,0.309,6.043,0.48,9.326c0.082,1.586,0.148,2.834,0.155,3.004
                            c0.001,0.02,0.002,0.037,0.002,0.051c-0.977,0.082-2.958,0.268-4.463,0.563c-0.892-2.151-2.295-5.562-2.647-6.424v-5.032
                            C27.429,14.584,28.943,14.251,31.751,14.244 M31.833,13.139c-3.139,0-4.893,0.368-7.662,1.939v5.908c0,0,2.169,5.283,3.093,7.5
                            c1.569-0.463,4.837-0.727,5.307-0.764c0.578-0.045,0.97-0.23,0.924-1.199c-0.026-0.555-0.617-11.744-0.646-12.556
                            C32.825,13.348,32.478,13.139,31.833,13.139L31.833,13.139z"/>
                        <path d="M24.886,17.939c1.062-0.879,4.085-2.217,5.631-1.433c-1.73,0.117-4.131,0.97-5.677,2.237
                            C24.862,18.375,24.886,17.939,24.886,17.939z"/>
                        <path d="M24.909,20.383c1.062-0.876,4.085-2.213,5.631-1.43c-1.73,0.117-4.131,0.969-5.678,2.238
                            C24.886,20.822,24.909,20.383,24.909,20.383z"/>
                        <path d="M24.955,22.67c1.062-0.879,4.085-1.939,5.631-1.156c-1.623,0.109-3.832,0.623-5.377,1.73
                            C25.116,23.061,24.955,22.67,24.955,22.67z"/>
                        <path d="M25.758,24.969c1.345-0.742,3.69-1.392,4.989-0.731c-1.4,0.093-3.241,0.491-4.711,1.31
                            C25.901,25.301,25.855,25.184,25.758,24.969z"/>
                    </g>
                    <path d="M21.862,46.783V49C40.07,44.165,42.771,32.811,42.725,5.894c-2.807,0.784-5.348,1.214-7.712,1.214
                        c-4.866,0-8.986-1.819-13.15-6.107l0,0c0,0,0,1.902,0,2.815c0.763,0.75,2.032,1.661,2.032,1.661
                        c3.412,2.484,7.025,3.658,11.118,3.658c1.749,0,3.623-0.211,5.677-0.649c-0.063,11.394-0.745,20.292-3.414,26.483
                        c-2.422,5.617-6.49,9.119-13.381,11.403L21.862,46.783z"/>
                    <path d="M28.994,39.631h-7.132v-1.663h7.132c0.379,0,0.682-0.151,0.875-0.446c0.282-0.428,0.29-1.081,0.023-1.707
                        c-1.097-2.554-6.136-14.373-6.186-14.494l-0.067-0.154v-8.674h-1.777V10.83h3.44v9.996c0.602,1.414,5.09,11.939,6.117,14.334
                        c0.492,1.15,0.431,2.375-0.164,3.274C30.755,39.194,29.931,39.631,28.994,39.631z"/>
                    <path d="M29.086,36.551c-0.092-0.23-4.154-9.74-4.154-9.74h-3.069v2.484c0.105-0.022,0.212-0.038,0.323-0.038
                        c0.777,0,1.407,0.632,1.407,1.407c0,0.78-0.63,1.408-1.407,1.408c-0.111,0-0.218-0.016-0.323-0.038v5.012c0,0,6.717,0,6.947,0
                        S29.179,36.781,29.086,36.551z M22.971,35.119c-0.458,0-0.831-0.372-0.831-0.83s0.373-0.83,0.831-0.83s0.832,0.372,0.832,0.83
                        S23.429,35.119,22.971,35.119z"/>
                    <path d="M26.109,12.044c0,0.323-0.263,0.587-0.585,0.587h-3.662v-1.939h3.662c0.322,0,0.585,0.264,0.585,0.587V12.044z"/>
                </g>
                <g>
                    <g>
                        <path d="M15.609,30.723c0,0-5.926-0.584-6.691-0.691c-0.647-0.092-0.832-0.368-0.786-1.039C8.17,28.44,8.779,17.152,8.802,16.6
                            c0.023-0.554,0.231-0.738,0.761-0.738c0.558,0,1.568,0.092,2.007,0.302c-0.046,0.415-0.046,0.737-0.093,0.968
                            c-0.507-0.209-1.684-0.256-1.684-0.256l-0.6,12.223h7.108L15.609,30.723z"/>
                        <path d="M11.975,14.244c2.808,0.007,4.323,0.34,6.474,1.488v5.032c-0.354,0.862-1.755,4.272-2.646,6.424
                            c-1.507-0.296-3.486-0.481-4.466-0.563c0.001-0.014,0.001-0.031,0.002-0.051c0.009-0.17,0.074-1.418,0.157-3.004
                            C11.666,20.287,11.919,15.46,11.975,14.244 M11.895,13.139c-0.647,0-0.993,0.209-1.016,0.829
                            c-0.03,0.812-0.621,12.001-0.647,12.556c-0.046,0.969,0.347,1.154,0.923,1.199c0.469,0.037,3.739,0.301,5.309,0.764
                            c0.922-2.217,3.092-7.5,3.092-7.5v-5.908C16.786,13.507,15.033,13.139,11.895,13.139L11.895,13.139z"/>
                        <path d="M18.84,17.939c-1.061-0.879-4.084-2.217-5.631-1.433c1.732,0.117,4.131,0.97,5.677,2.237
                            C18.862,18.375,18.84,17.939,18.84,17.939z"/>
                        <path d="M18.816,20.383c-1.061-0.876-4.084-2.213-5.631-1.43c1.732,0.117,4.131,0.969,5.677,2.238
                            C18.84,20.822,18.816,20.383,18.816,20.383z"/>
                        <path d="M18.771,22.67c-1.062-0.879-4.085-1.939-5.631-1.156c1.622,0.109,3.833,0.623,5.377,1.73
                            C18.609,23.061,18.771,22.67,18.771,22.67z"/>
                        <path d="M17.968,24.969c-1.343-0.742-3.688-1.392-4.99-0.731c1.403,0.093,3.242,0.491,4.712,1.31
                            C17.825,25.301,17.872,25.184,17.968,24.969z"/>
                    </g>
                    <path d="M21.955,39.631h-7.223c-0.937,0-1.76-0.437-2.261-1.196c-0.596-0.899-0.655-2.124-0.163-3.274
                        c1.025-2.395,5.515-12.92,6.117-14.334V10.83h3.438v1.662h-1.776v8.674L20.02,21.32c-0.051,0.121-5.09,11.94-6.185,14.494
                        c-0.269,0.626-0.259,1.279,0.023,1.707c0.193,0.295,0.496,0.446,0.874,0.446h7.223V39.631z"/>
                    <path d="M20.779,30.664c0-0.697,0.509-1.279,1.176-1.387v-2.467h-3.161c0,0-4.062,9.51-4.154,9.74
                        c-0.093,0.23,0.046,0.495,0.278,0.495c0.23,0,7.037,0,7.037,0l0.003-4.991C21.29,31.947,20.779,31.364,20.779,30.664z
                        M19.001,34.289c-0.598,0-1.083-0.487-1.083-1.086c0-0.598,0.485-1.084,1.083-1.084c0.6,0,1.085,0.486,1.085,1.084
                        C20.086,33.802,19.601,34.289,19.001,34.289z"/>
                    <path d="M21.955,12.631h-3.754c-0.322,0-0.585-0.264-0.585-0.587v-0.766c0-0.323,0.263-0.587,0.585-0.587h3.754V12.631z"/>
                    <path d="M19.833,46.372c-6.891-2.284-10.96-5.786-13.381-11.403c-2.669-6.191-3.351-15.09-3.416-26.483
                        c2.055,0.438,3.929,0.649,5.676,0.649c4.095,0,7.707-1.174,11.12-3.658c0,0,1.27-0.911,2.029-1.661
                        c0-0.157,0.556,0.104,0.556,0.104V1.796L21.862,1l0,0c-4.161,4.288-8.281,6.107-13.149,6.107c-2.364,0-4.903-0.43-7.712-1.214
                        C0.954,32.811,3.655,44.165,21.862,49l0.556-0.219v-1.939l-0.556-0.059L19.833,46.372z"/>
                </g>
                </svg>
            )
        } else if (this.props.format === "square") {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="10.5 802.5 160 160" style={{ fill: this.props.color }} className="mch-logo">
                <g>
                    <g>
                        <path d="M110.041,900.384c0,0,18.525-1.824,20.916-2.158c2.019-0.289,2.594-1.151,2.446-3.248c-0.115-1.724-2.015-37-2.087-38.728
                            c-0.071-1.73-0.718-2.306-2.384-2.306c-1.744,0-4.903,0.287-6.271,0.943c0.143,1.296,0.143,2.303,0.287,3.025
                            c1.588-0.653,5.266-0.801,5.266-0.801l1.881,38.197h-22.212L110.041,900.384z"/>
                        <path d="M121.4,848.888c0.178,3.8,0.966,18.885,1.5,29.143c0.256,4.957,0.462,8.856,0.484,9.389
                            c0.004,0.062,0.007,0.116,0.007,0.159c-3.053,0.257-9.244,0.838-13.947,1.761c-2.787-6.723-7.172-17.383-8.272-20.075v-15.727
                            C107.894,849.949,112.625,848.91,121.4,848.888 M121.656,845.435c-9.809,0-15.291,1.15-23.944,6.059v18.463
                            c0,0,6.779,16.51,9.666,23.438c4.903-1.446,15.116-2.271,16.584-2.387c1.806-0.142,3.031-0.719,2.887-3.747
                            c-0.081-1.734-1.928-36.7-2.019-39.237C124.756,846.088,123.672,845.435,121.656,845.435L121.656,845.435z"/>
                        <path d="M99.946,860.435c3.319-2.747,12.766-6.929,17.597-4.479c-5.406,0.367-12.909,3.031-17.74,6.99
                            C99.872,861.796,99.946,860.435,99.946,860.435z"/>
                        <path d="M100.019,868.071c3.318-2.736,12.765-6.916,17.597-4.468c-5.406,0.365-12.91,3.028-17.744,6.993
                            C99.946,869.443,100.019,868.071,100.019,868.071z"/>
                        <path d="M100.162,875.22c3.319-2.747,12.766-6.06,17.597-3.614c-5.072,0.341-11.975,1.947-16.803,5.407
                            C100.666,876.44,100.162,875.22,100.162,875.22z"/>
                        <path d="M102.671,882.404c4.204-2.319,11.532-4.351,15.591-2.285c-4.375,0.29-10.128,1.534-14.722,4.094
                            C103.119,883.44,102.975,883.074,102.671,882.404z"/>
                    </g>
                    <path d="M90.497,950.573v6.927c56.899-15.109,65.341-50.59,65.197-134.706c-8.772,2.449-16.712,3.794-24.1,3.794
                        c-15.207,0-28.081-5.685-41.094-19.084l0,0c0,0,0,5.942,0,8.796c2.385,2.344,6.351,5.19,6.351,5.19
                        c10.662,7.764,21.953,11.432,34.744,11.432c5.465,0,11.322-0.659,17.74-2.028c-0.197,35.607-2.328,63.413-10.668,82.759
                        c-7.569,17.555-20.282,28.497-41.815,35.636L90.497,950.573z"/>
                    <path d="M112.784,928.222H90.497v-5.196h22.287c1.185,0,2.132-0.473,2.735-1.394c0.881-1.338,0.906-3.378,0.072-5.336
                        c-3.428-7.979-19.174-44.915-19.331-45.293l-0.209-0.48v-27.106h-5.553v-5.197h10.75v31.236
                        c1.881,4.42,15.906,37.311,19.115,44.794c1.538,3.595,1.347,7.422-0.512,10.233C118.287,926.856,115.713,928.222,112.784,928.222z"
                        />
                    <path d="M113.072,918.598c-0.288-0.719-12.981-30.438-12.981-30.438H90.5v7.762c0.328-0.068,0.664-0.119,1.01-0.119
                        c2.427,0,4.396,1.977,4.396,4.397c0,2.438-1.969,4.399-4.396,4.399c-0.347,0-0.682-0.049-1.01-0.118v15.663c0,0,20.991,0,21.71,0
                        C112.928,920.145,113.362,919.315,113.072,918.598z M93.962,914.122c-1.432,0-2.597-1.163-2.597-2.594
                        c0-1.432,1.165-2.594,2.597-2.594c1.431,0,2.6,1.162,2.6,2.594C96.562,912.959,95.394,914.122,93.962,914.122z"/>
                    <path d="M103.768,842.014c0,1.009-0.821,1.833-1.827,1.833H90.497v-6.059h11.444c1.006,0,1.827,0.823,1.827,1.833V842.014z"/>
                </g>
                <g>
                    <g>
                        <path d="M70.956,900.384c0,0-18.519-1.824-20.909-2.158c-2.022-0.289-2.601-1.151-2.457-3.248
                            c0.119-1.727,2.022-37.003,2.094-38.728c0.072-1.73,0.722-2.306,2.378-2.306c1.744,0,4.9,0.287,6.272,0.943
                            c-0.144,1.296-0.144,2.303-0.291,3.025c-1.584-0.653-5.263-0.801-5.263-0.801l-1.875,38.197h22.212L70.956,900.384z"/>
                        <path d="M59.6,848.888c8.775,0.022,13.51,1.062,20.232,4.649v15.727c-1.106,2.692-5.485,13.35-8.269,20.075
                            c-4.709-0.926-10.894-1.504-13.957-1.761c0.003-0.043,0.003-0.097,0.007-0.159c0.028-0.532,0.231-4.432,0.49-9.389
                            C58.634,867.772,59.425,852.688,59.6,848.888 M59.35,845.435c-2.021,0-3.103,0.653-3.175,2.591
                            c-0.093,2.536-1.94,37.504-2.021,39.237c-0.144,3.027,1.084,3.605,2.885,3.747c1.465,0.115,11.684,0.941,16.59,2.387
                            c2.881-6.927,9.663-23.438,9.663-23.438v-18.462C74.634,846.585,69.156,845.435,59.35,845.435L59.35,845.435z"/>
                        <path d="M81.053,860.435c-3.315-2.747-12.762-6.929-17.596-4.479c5.412,0.367,12.909,3.031,17.741,6.99
                            C81.122,861.796,81.053,860.435,81.053,860.435z"/>
                        <path d="M80.978,868.071c-3.315-2.736-12.762-6.916-17.596-4.468c5.413,0.365,12.909,3.028,17.741,6.993
                            C81.053,869.443,80.978,868.071,80.978,868.071z"/>
                        <path d="M80.837,875.22c-3.319-2.747-12.765-6.06-17.597-3.614c5.068,0.341,11.978,1.947,16.803,5.407
                            C80.331,876.44,80.837,875.22,80.837,875.22z"/>
                        <path d="M78.328,882.404c-4.197-2.319-11.524-4.351-15.593-2.285c4.384,0.29,10.131,1.534,14.725,4.094
                            C77.881,883.44,78.028,883.074,78.328,882.404z"/>
                    </g>
                    <path d="M90.788,928.222H68.216c-2.928,0-5.5-1.365-7.066-3.736c-1.862-2.811-2.047-6.639-0.509-10.232
                        c3.203-7.484,17.234-40.375,19.116-44.794v-31.24H90.5v5.193h-5.55v27.106l-0.209,0.482c-0.159,0.378-15.906,37.312-19.328,45.293
                        c-0.841,1.956-0.81,3.997,0.072,5.335c0.603,0.92,1.549,1.393,2.731,1.393h22.572V928.222z"/>
                    <path d="M87.112,900.2c0-2.179,1.591-3.997,3.675-4.334v-7.709h-9.878c0,0-12.693,29.718-12.981,30.437
                        c-0.29,0.72,0.145,1.548,0.869,1.548c0.718,0,21.991,0,21.991,0l0.01-15.598C88.709,904.209,87.112,902.388,87.112,900.2z
                        M81.556,911.528c-1.869,0-3.384-1.521-3.384-3.395c0-1.867,1.515-3.387,3.384-3.387c1.875,0,3.39,1.52,3.39,3.387
                        C84.946,910.007,83.431,911.528,81.556,911.528z"/>
                    <path d="M90.788,843.847H79.056c-1.006,0-1.828-0.824-1.828-1.833v-2.395c0-1.011,0.821-1.835,1.828-1.835h11.731V843.847z"/>
                    <path d="M84.156,949.288c-21.535-7.139-34.25-18.081-41.815-35.636C34,894.307,31.869,866.497,31.666,830.894
                        c6.422,1.369,12.278,2.028,17.737,2.028c12.797,0,24.084-3.668,34.75-11.432c0,0,3.969-2.847,6.341-5.19
                        c0-0.49,1.737,0.325,1.737,0.325v-6.638l-1.734-2.487l0,0c-13.003,13.399-25.879,19.084-41.091,19.084
                        c-7.388,0-15.322-1.343-24.1-3.794C25.159,906.91,33.6,942.391,90.497,957.5l1.737-0.684v-6.061l-1.737-0.183L84.156,949.288z"/>
                </g>
                </svg>
            )
        } else {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169 50" className="mch-logo">
                <g>
                    <path fill="#881638" d="M69.228,22.381h-6.66v-1.49c0-0.467,0.234-0.759,0.696-0.869l0.021-0.005h0.021
                        c0.044-0.003,0.405-0.051,1.255-0.173v-7.992c0-0.103,0.006-0.205,0.012-0.305c0.004-0.066,0.008-0.131,0.01-0.199l-4.436,8.342
                        c-0.133,0.238-0.313,0.436-0.508,0.557c-0.221,0.139-0.457,0.207-0.719,0.207h-0.398c-0.263,0-0.498-0.068-0.718-0.207
                        c-0.197-0.121-0.376-0.318-0.508-0.553l-4.494-8.357c0.012,0.18,0.012,0.355,0.012,0.516v7.991c0.729,0.104,1.202,0.17,1.261,0.174
                        h0.021l0.015,0.005c0.463,0.11,0.697,0.402,0.697,0.869v1.49h-6.661v-1.49c0-0.467,0.234-0.759,0.695-0.869l0.021-0.005h0.021
                        c0.017,0,0.037,0,0.075-0.01c0.078-0.028,0.197-0.051,0.365-0.077c0.097-0.015,0.233-0.035,0.386-0.062
                        c0.039-0.006,0.079-0.013,0.12-0.02V8.526c-0.04-0.004-0.081-0.011-0.119-0.019c-0.152-0.028-0.289-0.049-0.387-0.064
                        c-0.088-0.014-0.161-0.029-0.219-0.045c-0.034-0.01-0.075-0.018-0.089-0.018L48.99,8.379l-0.032-0.008
                        c-0.038-0.014-0.059-0.014-0.075-0.014h-0.021l-0.021-0.006c-0.461-0.109-0.695-0.398-0.695-0.865V5.992h4.106
                        c0.08,0,0.159,0.008,0.229,0.015c0.048,0.005,0.094,0.009,0.126,0.009c0.108,0,0.235,0.024,0.316,0.053
                        c0.119,0.031,0.188,0.107,0.245,0.164c0.01,0.013,0.021,0.025,0.033,0.039c0.077,0.075,0.154,0.178,0.227,0.299l4.79,8.891
                        c0.092,0.172,0.164,0.34,0.233,0.506c0.035,0.078,0.067,0.156,0.105,0.239c0.052,0.116,0.106,0.239,0.159,0.364
                        c0.046-0.121,0.093-0.236,0.145-0.359c0.128-0.311,0.239-0.549,0.361-0.771l4.741-8.86c0.018-0.029,0.031-0.053,0.042-0.071
                        c0.051-0.09,0.098-0.17,0.167-0.236c0.076-0.078,0.17-0.172,0.308-0.205c0.094-0.025,0.198-0.051,0.312-0.051
                        c0.029,0,0.069-0.004,0.114-0.009C64.968,6,65.04,5.992,65.123,5.992h4.105v1.494c0,0.467-0.233,0.756-0.695,0.865l-0.028,0.006
                        c-0.03,0.009-0.08,0.023-0.147,0.023c-0.013,0-0.054,0.008-0.088,0.018c-0.058,0.016-0.131,0.031-0.217,0.045
                        c-0.087,0.013-0.191,0.033-0.314,0.054c-0.055,0.011-0.11,0.021-0.171,0.028v11.322c0.061,0.012,0.117,0.02,0.171,0.029
                        c0.123,0.023,0.229,0.04,0.314,0.053c0.086,0.014,0.159,0.031,0.217,0.046c0.034,0.009,0.075,0.021,0.089,0.021l0,0
                        c0.067,0,0.117,0.012,0.146,0.02l0.028,0.006c0.462,0.11,0.695,0.402,0.695,0.869V22.381z"/>
                    <path fill="#881638" d="M87.018,22.381h-5.774v-1.314c0-0.33,0.18-0.573,0.55-0.743c0.02-0.011,0.049-0.022,0.099-0.037
                        c0.048-0.019,0.11-0.044,0.189-0.074l-0.03-0.079l-0.753-2.049h-6.121l-0.768,2.135c0.071,0.027,0.13,0.05,0.175,0.067
                        c0.047,0.015,0.078,0.026,0.102,0.04c0.366,0.167,0.546,0.41,0.546,0.74v1.314h-5.773v-1.314c0-0.33,0.179-0.573,0.549-0.743
                        l0.01-0.004c0.086-0.034,0.379-0.159,0.821-0.345l0.321-0.135l5.424-13.848h3.307l5.446,13.848l0.332,0.139
                        c0.437,0.184,0.727,0.308,0.812,0.341c0.351,0.134,0.537,0.392,0.537,0.747V22.381z M76.087,15.607h4.322l-1.746-4.736
                        c-0.048-0.13-0.096-0.266-0.146-0.412c-0.044-0.127-0.091-0.262-0.144-0.407c-0.051-0.144-0.097-0.299-0.142-0.458
                        c-0.045,0.149-0.088,0.298-0.125,0.441c-0.071,0.27-0.157,0.494-0.232,0.691c-0.021,0.057-0.042,0.112-0.062,0.168L76.087,15.607z"
                        />
                    <path fill="#881638" d="M92.35,22.558h-0.333c-1.41,0-2.972-0.287-4.646-0.853l-0.124-0.042v-4.437h1.447
                        c0.367,0,0.599,0.181,0.688,0.537c0.045,0.205,0.107,0.757,0.193,1.742c0.612,0.167,1.551,0.246,2.862,0.246
                        c0.801,0,1.423-0.187,1.852-0.553c0.419-0.361,0.623-0.879,0.623-1.588c0-0.369-0.094-0.695-0.274-0.932
                        c-0.176-0.234-0.438-0.44-0.776-0.609c-0.376-0.188-0.739-0.332-1.145-0.455c-0.157-0.047-0.317-0.092-0.479-0.137
                        c-0.29-0.079-0.59-0.161-0.88-0.264c-0.444-0.148-0.892-0.323-1.366-0.539c-0.429-0.193-0.848-0.48-1.246-0.854
                        c-0.375-0.351-0.658-0.779-0.865-1.309c-0.227-0.549-0.346-1.209-0.346-1.904c0-0.624,0.125-1.223,0.37-1.78
                        c0.226-0.563,0.593-1.101,1.061-1.544c0.444-0.422,1-0.769,1.696-1.058c0.663-0.272,1.438-0.411,2.308-0.411
                        c1.571,0,3.138,0.312,4.656,0.924l0.115,0.047v4.407h-1.446c-0.252,0-0.574-0.097-0.689-0.562
                        c-0.046-0.207-0.108-0.765-0.195-1.712c-0.519-0.198-1.33-0.3-2.418-0.3c-0.388,0-0.707,0.043-1.008,0.127
                        c-0.312,0.11-0.544,0.238-0.707,0.384c-0.196,0.173-0.349,0.374-0.443,0.579c-0.091,0.199-0.139,0.455-0.139,0.742
                        c0,0.307,0.098,0.579,0.291,0.814c0.198,0.242,0.453,0.443,0.761,0.594c0.427,0.217,0.798,0.365,1.166,0.477
                        c0.334,0.1,0.662,0.212,1.008,0.327c0.115,0.038,0.231,0.077,0.349,0.119c0.475,0.155,0.928,0.361,1.368,0.56
                        c0.46,0.205,0.88,0.484,1.247,0.831c0.399,0.375,0.689,0.778,0.887,1.237c0.216,0.456,0.324,1.038,0.324,1.778
                        c0,0.719-0.124,1.381-0.39,2.079c-0.26,0.689-0.63,1.254-1.129,1.729c-0.482,0.482-1.092,0.87-1.811,1.148
                        C94.049,22.424,93.26,22.558,92.35,22.558z"/>
                    <path fill="#881638" d="M108.373,22.381h-6.705v-1.49c0-0.467,0.234-0.759,0.697-0.869l0.027-0.006
                        c0.031-0.008,0.082-0.02,0.148-0.02c0.012,0,0.055-0.012,0.087-0.021c0.06-0.015,0.133-0.032,0.219-0.046
                        c0.085-0.013,0.191-0.029,0.314-0.053c0.053-0.01,0.11-0.018,0.169-0.029V8.802h-2.904c-0.043,0.442-0.083,0.702-0.097,0.774
                        l-0.002,0.016c-0.117,0.463-0.438,0.561-0.688,0.561h-1.469v-4.16h13.684v4.16h-1.449c-0.362,0-0.621-0.205-0.709-0.561
                        l-0.004-0.016c-0.036-0.211-0.067-0.466-0.097-0.754h-2.903v11.025c0.059,0.012,0.116,0.02,0.17,0.029
                        c0.122,0.023,0.229,0.04,0.312,0.053c0.089,0.014,0.161,0.031,0.219,0.046c0.034,0.009,0.076,0.021,0.089,0.021l0,0
                        c0.065,0,0.116,0.012,0.147,0.02l0.027,0.006c0.471,0.112,0.718,0.41,0.718,0.869V22.381z"/>
                    <path fill="#881638" d="M124.716,22.558c-1.175,0-2.242-0.206-3.262-0.633c-0.996-0.417-1.839-1-2.502-1.729
                        c-0.702-0.774-1.236-1.668-1.588-2.656c-0.366-1.027-0.543-2.121-0.543-3.342c0-1.234,0.206-2.377,0.611-3.39
                        c0.406-1.019,0.971-1.879,1.725-2.633c0.783-0.781,1.652-1.354,2.655-1.748c1.013-0.403,2.162-0.61,3.412-0.61
                        c1.373,0,3.086,0.393,5.097,1.163l0.118,0.046v4.39h-1.472c-0.366,0-0.598-0.181-0.687-0.54c-0.045-0.2-0.107-0.734-0.193-1.674
                        c-1.11-0.313-2.054-0.468-2.886-0.468c-0.763,0-1.421,0.118-2.011,0.362c-0.611,0.254-1.118,0.606-1.552,1.084
                        c-0.44,0.485-0.777,1.045-1.027,1.715c-0.257,0.683-0.388,1.457-0.388,2.303c0,0.874,0.127,1.637,0.388,2.33
                        c0.279,0.743,0.582,1.272,0.983,1.711c0.411,0.455,0.899,0.801,1.489,1.066c0.554,0.242,1.135,0.359,1.83,0.359
                        c0.396,0,0.762-0.023,1.084-0.066c0.331-0.045,0.625-0.106,0.875-0.192c0.238-0.078,0.492-0.2,0.734-0.355
                        c0.21-0.133,0.441-0.314,0.706-0.557c0.082-0.081,0.189-0.136,0.285-0.183c0.129-0.065,0.251-0.065,0.349-0.065
                        c0.235,0,0.431,0.083,0.562,0.244l1.276,1.395l-0.1,0.124c-0.666,0.829-1.495,1.464-2.462,1.892
                        C127.234,22.338,126.056,22.558,124.716,22.558z"/>
                    <path fill="#881638" d="M143,22.381h-12.156v-1.49c0-0.467,0.234-0.759,0.697-0.869l0.028-0.006c0.03-0.008,0.081-0.02,0.147-0.02
                        c0.014,0,0.055-0.012,0.087-0.021c0.059-0.015,0.132-0.032,0.218-0.046c0.1-0.015,0.233-0.036,0.389-0.062
                        c0.038-0.006,0.077-0.013,0.119-0.02V8.526c-0.042-0.004-0.081-0.011-0.118-0.019c-0.155-0.028-0.29-0.049-0.39-0.064
                        c-0.086-0.014-0.159-0.029-0.218-0.045c-0.033-0.01-0.074-0.018-0.089-0.018l0,0c-0.064,0-0.115-0.015-0.145-0.023l-0.028-0.006
                        c-0.463-0.109-0.697-0.398-0.697-0.865V5.992H143v4.581h-1.47c-0.25,0-0.572-0.098-0.688-0.561l-0.005-0.024l-0.011-0.092
                        c-0.025-0.197-0.077-0.586-0.129-1.163h-4.809v4.061h5.429v2.673h-5.429v4.152h4.809c0.053-0.577,0.104-0.969,0.129-1.163
                        l0.016-0.115c0.116-0.464,0.438-0.562,0.688-0.562H143V22.381z"/>
                    <path fill="#881638" d="M155.316,22.381h-11.979v-1.49c0-0.467,0.234-0.759,0.698-0.869l0.021-0.005h0.021
                        c0.015,0,0.035,0,0.074-0.01c0.076-0.028,0.196-0.051,0.364-0.077c0.099-0.015,0.233-0.036,0.387-0.062
                        c0.039-0.006,0.078-0.013,0.118-0.02V8.526c-0.04-0.004-0.079-0.011-0.117-0.019c-0.154-0.028-0.289-0.049-0.388-0.064
                        c-0.087-0.014-0.159-0.029-0.219-0.045c-0.032-0.01-0.074-0.018-0.089-0.018l-0.025-0.002l-0.031-0.008
                        c-0.039-0.014-0.06-0.014-0.074-0.014h-0.021l-0.021-0.006c-0.464-0.109-0.698-0.398-0.698-0.865V5.992h6.706v1.494
                        c0,0.467-0.234,0.756-0.695,0.865l-0.021,0.006h-0.021c-0.017,0-0.038,0-0.075,0.014c-0.078,0.023-0.197,0.05-0.365,0.072
                        c-0.098,0.016-0.235,0.036-0.389,0.064c-0.037,0.008-0.078,0.016-0.117,0.019v11.025h4.807l0.005-0.047
                        c0.035-0.463,0.053-0.673,0.076-0.764c0.117-0.467,0.439-0.562,0.688-0.562h1.381V22.381z"/>
                    <path fill="#881638" d="M167.612,22.381h-11.978v-1.49c0-0.467,0.233-0.759,0.695-0.869l0.021-0.005h0.021
                        c0.016,0,0.037,0,0.075-0.01c0.078-0.028,0.197-0.051,0.364-0.077c0.098-0.015,0.233-0.035,0.387-0.062
                        c0.038-0.006,0.079-0.013,0.12-0.02V8.526c-0.04-0.004-0.081-0.011-0.118-0.019c-0.155-0.028-0.29-0.049-0.389-0.064
                        c-0.086-0.014-0.16-0.029-0.217-0.045c-0.034-0.01-0.075-0.018-0.089-0.018l-0.025-0.002l-0.032-0.008
                        c-0.038-0.014-0.059-0.014-0.075-0.014h-0.021l-0.021-0.006c-0.462-0.109-0.695-0.398-0.695-0.865V5.992h6.705v1.494
                        c0,0.467-0.234,0.756-0.697,0.865l-0.021,0.006h-0.021c-0.015,0-0.035,0-0.075,0.014c-0.076,0.023-0.195,0.05-0.363,0.072
                        c-0.1,0.016-0.234,0.036-0.39,0.064c-0.038,0.008-0.076,0.016-0.117,0.019v11.025h4.807l0.004-0.047
                        c0.038-0.463,0.054-0.673,0.077-0.764c0.115-0.467,0.438-0.562,0.688-0.562h1.381V22.381z"/>
                    <path fill="#881638" d="M91.329,44.119h-7.325v-1.648c0-0.514,0.269-0.849,0.778-0.967l0.026-0.006h0.025
                        c0.017,0,0.035,0,0.071-0.014c0.087-0.027,0.217-0.055,0.4-0.08c0.091-0.015,0.206-0.033,0.338-0.059
                        c0.052-0.01,0.104-0.017,0.16-0.027v-4.783H78.13v4.783c0.035,0.006,0.07,0.013,0.104,0.018c0.165,0.028,0.312,0.051,0.417,0.068
                        c0.096,0.014,0.176,0.031,0.239,0.049c0.033,0.01,0.077,0.021,0.091,0.022h0.038l0.032,0.009c0.037,0.014,0.056,0.014,0.07,0.014
                        h0.027l0.026,0.006c0.509,0.118,0.778,0.453,0.778,0.967v1.648h-7.351v-1.648c0-0.514,0.27-0.849,0.778-0.967l0.032-0.006
                        c0.031-0.01,0.091-0.022,0.165-0.022c0.011-0.001,0.055-0.013,0.088-0.022c0.063-0.017,0.144-0.035,0.24-0.049
                        c0.105-0.018,0.251-0.04,0.417-0.068c0.033-0.005,0.068-0.012,0.103-0.018V29.106c-0.033-0.005-0.066-0.009-0.096-0.015
                        c-0.171-0.031-0.319-0.055-0.424-0.071c-0.097-0.013-0.177-0.032-0.24-0.05c-0.033-0.009-0.077-0.018-0.091-0.018h-0.038
                        l-0.032-0.014c-0.037-0.013-0.056-0.013-0.07-0.013h-0.027l-0.026-0.007c-0.509-0.117-0.778-0.453-0.778-0.967v-1.647h7.351v1.647
                        c0,0.514-0.27,0.85-0.778,0.967l-0.026,0.007h-0.027c-0.015,0-0.033,0-0.07,0.013l-0.035,0.014h-0.038
                        c-0.011,0-0.055,0.009-0.088,0.018c-0.063,0.018-0.144,0.037-0.239,0.05c-0.107,0.017-0.256,0.04-0.424,0.071
                        c-0.031,0.006-0.064,0.01-0.097,0.015v4.663h7.673v-4.664c-0.056-0.006-0.106-0.017-0.157-0.026
                        c-0.134-0.024-0.25-0.045-0.341-0.059c-0.161-0.021-0.281-0.046-0.366-0.069c-0.057-0.004-0.101-0.018-0.127-0.024h-0.004
                        l-0.026-0.007c-0.51-0.117-0.778-0.453-0.778-0.967v-1.647h7.325v1.647c0,0.514-0.269,0.85-0.777,0.967l-0.031,0.007
                        c-0.033,0.009-0.091,0.026-0.166,0.026c-0.013,0-0.057,0.009-0.087,0.018c-0.065,0.018-0.145,0.037-0.24,0.05
                        c-0.091,0.014-0.206,0.034-0.341,0.059c-0.049,0.01-0.102,0.021-0.157,0.026v12.213c0.057,0.011,0.109,0.018,0.159,0.027
                        c0.135,0.025,0.249,0.044,0.339,0.059c0.096,0.014,0.175,0.031,0.24,0.049c0.03,0.01,0.074,0.021,0.09,0.022
                        c0.001,0,0.001,0,0.001,0c0.071,0,0.129,0.013,0.162,0.022h0.006l0.025,0.006c0.509,0.118,0.777,0.453,0.777,0.967V44.119z"/>
                    <path fill="#881638" d="M101.922,44.311c-1.303,0-2.543-0.23-3.685-0.688c-1.105-0.443-2.069-1.082-2.864-1.903
                        c-0.786-0.807-1.407-1.779-1.848-2.881c-0.426-1.065-0.642-2.286-0.642-3.64s0.21-2.535,0.642-3.613
                        c0.44-1.102,1.062-2.07,1.848-2.882c0.795-0.821,1.759-1.46,2.864-1.899c1.143-0.461,2.382-0.691,3.685-0.691
                        c1.367,0,2.566,0.225,3.667,0.691c1.159,0.488,2.095,1.111,2.859,1.899c0.769,0.793,1.366,1.736,1.824,2.882
                        c0.44,1.104,0.665,2.318,0.665,3.613c0,1.311-0.225,2.535-0.665,3.64c-0.458,1.146-1.056,2.089-1.824,2.881
                        c-0.795,0.821-1.758,1.46-2.864,1.903C104.427,44.087,103.229,44.311,101.922,44.311z M101.922,29.336
                        c-0.865,0-1.609,0.131-2.212,0.389c-0.639,0.272-1.192,0.666-1.648,1.166c-0.458,0.508-0.806,1.109-1.057,1.841
                        c-0.254,0.736-0.371,1.524-0.371,2.468c0,0.951,0.121,1.768,0.371,2.494c0.251,0.735,0.599,1.336,1.057,1.84
                        c0.456,0.5,1.01,0.895,1.648,1.167c0.603,0.261,1.347,0.391,2.212,0.391c0.863,0,1.578-0.13,2.19-0.391
                        c0.639-0.272,1.192-0.667,1.648-1.167c0.469-0.516,0.805-1.115,1.03-1.834c0.251-0.805,0.373-1.62,0.373-2.5
                        c0-0.871-0.118-1.657-0.373-2.475c-0.226-0.717-0.562-1.318-1.03-1.834c-0.456-0.5-1.01-0.894-1.649-1.166
                        C103.5,29.463,102.785,29.336,101.922,29.336z"/>
                    <path fill="#881638" d="M119.842,44.119h-7.327v-1.648c0-0.514,0.271-0.849,0.779-0.967l0.031-0.006
                        c0.027-0.008,0.07-0.021,0.125-0.021c0.085-0.026,0.207-0.048,0.368-0.072c0.105-0.018,0.252-0.04,0.418-0.068
                        c0.034-0.006,0.068-0.012,0.104-0.018V29.106c-0.034-0.005-0.067-0.009-0.099-0.015c-0.171-0.031-0.318-0.055-0.424-0.071
                        c-0.096-0.013-0.177-0.032-0.24-0.05c-0.032-0.009-0.076-0.018-0.09-0.018h-0.038l-0.033-0.014
                        c-0.038-0.013-0.057-0.013-0.071-0.013h-0.027l-0.025-0.007c-0.509-0.117-0.779-0.453-0.779-0.967v-1.647h7.407
                        c1.152,0,2.224,0.146,3.093,0.418c0.824,0.261,1.555,0.663,2.172,1.2c0.577,0.502,1.011,1.113,1.287,1.816
                        c0.281,0.718,0.424,1.496,0.424,2.314c0,0.886-0.152,1.696-0.45,2.418c-0.309,0.746-0.736,1.363-1.305,1.883
                        c-0.55,0.501-1.287,0.912-2.193,1.225c-0.826,0.283-1.814,0.42-3.027,0.42h-1.904v3.318c0.035,0.006,0.071,0.013,0.104,0.018
                        c0.166,0.028,0.312,0.051,0.417,0.068c0.097,0.014,0.177,0.032,0.24,0.049c0.033,0.01,0.077,0.021,0.091,0.022l0,0
                        c0.071,0,0.131,0.013,0.162,0.022h0.006l0.026,0.006c0.508,0.118,0.778,0.453,0.778,0.967V44.119z M118.018,35.017h1.904
                        c0.582,0,1.081-0.066,1.487-0.202c0.432-0.144,0.746-0.323,0.99-0.568l0.006-0.008c0.259-0.236,0.457-0.543,0.587-0.916
                        c0.133-0.349,0.204-0.799,0.204-1.268c0-0.422-0.066-0.803-0.202-1.166c-0.127-0.338-0.319-0.623-0.589-0.869
                        c-0.252-0.231-0.577-0.411-0.996-0.551c-0.406-0.135-0.905-0.205-1.487-0.205h-1.904V35.017z"/>
                    <path fill="#881638" d="M141.705,44.119h-13.23v-1.648c0-0.514,0.27-0.849,0.778-0.967l0.032-0.006
                        c0.033-0.01,0.091-0.022,0.165-0.022c0.011-0.001,0.056-0.013,0.088-0.022c0.063-0.018,0.144-0.035,0.24-0.049
                        c0.105-0.018,0.251-0.04,0.417-0.068c0.035-0.005,0.068-0.012,0.105-0.018V29.106c-0.034-0.005-0.068-0.009-0.1-0.015
                        c-0.171-0.031-0.318-0.055-0.423-0.071c-0.097-0.013-0.177-0.032-0.24-0.05c-0.032-0.009-0.077-0.018-0.091-0.018l0,0
                        c-0.071,0-0.129-0.018-0.162-0.026h-0.006l-0.026-0.007c-0.508-0.117-0.778-0.453-0.778-0.967v-1.647h13.23v5.021h-1.623
                        c-0.399,0-0.674-0.224-0.776-0.632l-0.004-0.026l-0.014-0.101c-0.03-0.227-0.081-0.626-0.137-1.232h-5.149v4.338h5.88v2.958h-5.88
                        v4.433h5.149c0.056-0.605,0.106-1.004,0.137-1.23l0.018-0.131c0.103-0.405,0.377-0.629,0.776-0.629h1.623V44.119z"/>
                </g>
                <path fill="#91A1CE" d="M1.993,7.23c0,0-0.277,14.307,1.569,22.522s6.669,15.784,18.393,18.37c0-12.555,0-45.785,0-45.785
                    s-3.9,4.246-9.346,5.445C7.163,8.984,1.993,7.23,1.993,7.23z"/>
                <path fill="#C3B3D8" d="M41.732,7.23c0,0,0.277,14.307-1.569,22.522s-6.577,15.784-18.3,18.37c0-12.555,0-45.785,0-45.785
                    s3.808,4.246,9.254,5.445C36.562,8.984,41.732,7.23,41.732,7.23z"/>
                <polygon fill="#FFFFFF" points="11.293,16.369 11.478,13.693 14.431,13.785 19.139,15.262 19.324,11.477 24.678,11.662 
                    24.678,15.354 27.354,13.97 32.154,13.508 32.524,16.277 34.277,16.277 35.108,29.293 28.647,29.939 30.955,36.031 30.308,38.339 
                    13.785,38.523 12.585,36.216 15.262,29.847 8.616,29.293 9.354,16.186 "/>
                <g>
                    <g>
                        <path fill="#023967" d="M15.609,30.723c0,0-5.926-0.584-6.691-0.691c-0.647-0.092-0.832-0.368-0.786-1.039
                            C8.17,28.44,8.779,17.152,8.802,16.6c0.023-0.554,0.231-0.738,0.761-0.738c0.558,0,1.568,0.092,2.007,0.302
                            c-0.046,0.415-0.046,0.737-0.093,0.968c-0.507-0.209-1.684-0.256-1.684-0.256l-0.6,12.223h7.108L15.609,30.723z"/>
                        <path fill="#023967" d="M11.975,14.244c2.808,0.007,4.323,0.34,6.474,1.488v5.032c-0.354,0.862-1.755,4.272-2.646,6.424
                            c-1.507-0.296-3.486-0.481-4.466-0.563c0.001-0.014,0.001-0.031,0.002-0.051c0.009-0.17,0.074-1.418,0.157-3.004
                            C11.666,20.287,11.919,15.46,11.975,14.244 M11.895,13.139c-0.647,0-0.993,0.209-1.016,0.829
                            c-0.03,0.812-0.621,12.001-0.647,12.556c-0.046,0.969,0.347,1.154,0.923,1.199c0.469,0.037,3.739,0.301,5.309,0.764
                            c0.922-2.217,3.092-7.5,3.092-7.5v-5.908C16.786,13.507,15.033,13.139,11.895,13.139L11.895,13.139z"/>
                        <path fill="#023967" d="M18.84,17.939c-1.061-0.879-4.084-2.217-5.631-1.433c1.732,0.117,4.131,0.97,5.677,2.237
                            C18.862,18.375,18.84,17.939,18.84,17.939z"/>
                        <path fill="#023967" d="M18.816,20.383c-1.061-0.876-4.084-2.213-5.631-1.43c1.732,0.117,4.131,0.969,5.677,2.238
                            C18.84,20.822,18.816,20.383,18.816,20.383z"/>
                        <path fill="#023967" d="M18.771,22.67c-1.062-0.879-4.085-1.939-5.631-1.156c1.622,0.109,3.833,0.623,5.377,1.73
                            C18.609,23.061,18.771,22.67,18.771,22.67z"/>
                        <path fill="#023967" d="M17.968,24.969c-1.343-0.742-3.688-1.392-4.99-0.731c1.403,0.093,3.242,0.491,4.712,1.31
                            C17.825,25.301,17.872,25.184,17.968,24.969z"/>
                    </g>
                    <path fill="#023967" d="M21.955,39.631h-7.223c-0.937,0-1.76-0.437-2.261-1.196c-0.596-0.899-0.655-2.124-0.163-3.274
                        c1.025-2.395,5.515-12.92,6.117-14.334V10.83h3.438v1.662h-1.776v8.674L20.02,21.32c-0.051,0.121-5.09,11.94-6.185,14.494
                        c-0.269,0.626-0.259,1.279,0.023,1.707c0.193,0.295,0.496,0.446,0.874,0.446h7.223V39.631z"/>
                    <path fill="#023967" d="M20.779,30.664c0-0.697,0.509-1.279,1.176-1.387v-2.467h-3.161c0,0-4.062,9.51-4.154,9.74
                        c-0.093,0.23,0.046,0.495,0.278,0.495c0.23,0,7.037,0,7.037,0l0.003-4.991C21.29,31.947,20.779,31.364,20.779,30.664z
                        M19.001,34.289c-0.598,0-1.083-0.487-1.083-1.086c0-0.598,0.485-1.084,1.083-1.084c0.6,0,1.085,0.486,1.085,1.084
                        C20.086,33.802,19.601,34.289,19.001,34.289z"/>
                    <path fill="#023967" d="M21.955,12.631h-3.754c-0.322,0-0.585-0.264-0.585-0.587v-0.766c0-0.323,0.263-0.587,0.585-0.587h3.754
                        V12.631z"/>
                    <path fill="#023967" d="M19.833,46.372c-6.891-2.284-10.96-5.786-13.381-11.403c-2.669-6.191-3.351-15.09-3.416-26.483
                        c2.055,0.438,3.929,0.649,5.676,0.649c4.095,0,7.707-1.174,11.12-3.658c0,0,1.27-0.911,2.029-1.661
                        c0-0.157,0.556,0.104,0.556,0.104V1.796L21.862,1l0,0c-4.161,4.288-8.281,6.107-13.149,6.107c-2.364,0-4.903-0.43-7.712-1.214
                        C0.954,32.811,3.655,44.165,21.862,49l0.556-0.219v-1.939l-0.556-0.059L19.833,46.372z"/>
                </g>
                <g>
                    <g>
                        <path fill="#503F77" d="M28.116,30.723c0,0,5.928-0.584,6.693-0.691c0.646-0.092,0.83-0.368,0.783-1.039
                            c-0.037-0.552-0.645-11.84-0.668-12.393c-0.023-0.554-0.23-0.738-0.763-0.738c-0.558,0-1.569,0.092-2.007,0.302
                            c0.046,0.415,0.046,0.737,0.092,0.968c0.508-0.209,1.685-0.256,1.685-0.256l0.602,12.223h-7.108L28.116,30.723z"/>
                        <path fill="#503F77" d="M31.751,14.244c0.057,1.216,0.309,6.043,0.48,9.326c0.082,1.586,0.148,2.834,0.155,3.004
                            c0.001,0.02,0.002,0.037,0.002,0.051c-0.977,0.082-2.958,0.268-4.463,0.563c-0.892-2.151-2.295-5.562-2.647-6.424v-5.032
                            C27.429,14.584,28.943,14.251,31.751,14.244 M31.833,13.139c-3.139,0-4.893,0.368-7.662,1.939v5.908c0,0,2.169,5.283,3.093,7.5
                            c1.569-0.463,4.837-0.727,5.307-0.764c0.578-0.045,0.97-0.23,0.924-1.199c-0.026-0.555-0.617-11.744-0.646-12.556
                            C32.825,13.348,32.478,13.139,31.833,13.139L31.833,13.139z"/>
                        <path fill="#503F77" d="M24.886,17.939c1.062-0.879,4.085-2.217,5.631-1.433c-1.73,0.117-4.131,0.97-5.677,2.237
                            C24.862,18.375,24.886,17.939,24.886,17.939z"/>
                        <path fill="#503F77" d="M24.909,20.383c1.062-0.876,4.085-2.213,5.631-1.43c-1.73,0.117-4.131,0.969-5.678,2.238
                            C24.886,20.822,24.909,20.383,24.909,20.383z"/>
                        <path fill="#503F77" d="M24.955,22.67c1.062-0.879,4.085-1.939,5.631-1.156c-1.623,0.109-3.832,0.623-5.377,1.73
                            C25.116,23.061,24.955,22.67,24.955,22.67z"/>
                        <path fill="#503F77" d="M25.758,24.969c1.345-0.742,3.69-1.392,4.989-0.731c-1.4,0.093-3.241,0.491-4.711,1.31
                            C25.901,25.301,25.855,25.184,25.758,24.969z"/>
                    </g>
                    <path fill="#503F77" d="M21.862,46.783V49C40.07,44.165,42.771,32.811,42.725,5.894c-2.807,0.784-5.348,1.214-7.712,1.214
                        c-4.866,0-8.986-1.819-13.15-6.107l0,0c0,0,0,1.902,0,2.815c0.763,0.75,2.032,1.661,2.032,1.661
                        c3.412,2.484,7.025,3.658,11.118,3.658c1.749,0,3.623-0.211,5.677-0.649c-0.063,11.394-0.745,20.292-3.414,26.483
                        c-2.422,5.617-6.49,9.119-13.381,11.403L21.862,46.783z"/>
                    <path fill="#503F77" d="M28.994,39.631h-7.132v-1.663h7.132c0.379,0,0.682-0.151,0.875-0.446c0.282-0.428,0.29-1.081,0.023-1.707
                        c-1.097-2.554-6.136-14.373-6.186-14.494l-0.067-0.154v-8.674h-1.777V10.83h3.44v9.996c0.602,1.414,5.09,11.939,6.117,14.334
                        c0.492,1.15,0.431,2.375-0.164,3.274C30.755,39.194,29.931,39.631,28.994,39.631z"/>
                    <path fill="#503F77" d="M29.086,36.551c-0.092-0.23-4.154-9.74-4.154-9.74h-3.069v2.484c0.105-0.022,0.212-0.038,0.323-0.038
                        c0.777,0,1.407,0.632,1.407,1.407c0,0.78-0.63,1.408-1.407,1.408c-0.111,0-0.218-0.016-0.323-0.038v5.012c0,0,6.717,0,6.947,0
                        S29.179,36.781,29.086,36.551z M22.971,35.119c-0.458,0-0.831-0.372-0.831-0.83s0.373-0.83,0.831-0.83s0.832,0.372,0.832,0.83
                        S23.429,35.119,22.971,35.119z"/>
                    <path fill="#503F77" d="M26.109,12.044c0,0.323-0.263,0.587-0.585,0.587h-3.662v-1.939h3.662c0.322,0,0.585,0.264,0.585,0.587
                        V12.044z"/>
                </g>
                </svg>
            )
        }
    }
}

export default Logo