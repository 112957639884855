import React from 'react'
import settings from '../settings/topBanner.json'

class TopBanner extends React.Component {
    render() {
        const enabled = settings.active
        if( enabled ) {
            return (
                <section className="section has-text-white"  style={{ backgroundColor: '#f95959'}}>
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            <div className="content">
                                <div className="top-banner">
                                    { settings.message }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        } else {
            return (
                <div style={{ display: 'none' }}></div>
            )
        }
    }
}

export default TopBanner
